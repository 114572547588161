import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Introduction from "../components/introduction"
import Approach from "../components/approach"
import Testimonial from "../components/testimonial"
import { graphql } from "gatsby"

const ApproachPage = ({ data }) => {
  return (
    <Layout>

      <Seo description={data.allDatoCmsApproach?.edges[0].node?.metaDescription} title={data.allDatoCmsApproach.edges[0].node.metaTitle} />

      <Introduction title="Our Approach" subtitle={data.datoCmsApproach?.ourApproach} caption={data.datoCmsApproach?.ourApproachSmallText} />

      <Approach tiles={data.allDatoCmsApproach.edges[0]?.node.approachTypes} background="#ECECEC" image={data.allDatoCmsApproach.edges[0]?.node.approachImage.gatsbyImageData} />

      <Testimonial author={data.datoCmsApproach?.testimonialName} text={data.datoCmsApproach?.testimonialQuotation} />

    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsApproach {
      testimonialName
      testimonialQuotation
      ourApproachSmallText
          ourApproach
    }
    allDatoCmsApproach {
      
      edges {
        node {
          id
          metaTitle
         metaDescription
          ourApproachSmallText
          ourApproach
          approachImage {
            gatsbyImageData
          }
          approachTypes {
            name
            content
            background {
              rgb
            }
          }
        }
      }
    }
  }
`

export default ApproachPage
