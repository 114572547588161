import React from 'react'
import {GatsbyImage} from 'gatsby-plugin-image'
const Image = ({background, image, tiles}) =>{

    const cards = tiles.map((tile, index) => { 
        return ( 
        <div className={"flex mp0 mt20 mb20 "+ (index === 1 ?  'pl20 pr20 bl1 br1' : '') + (index === 0 ?  'pr20' : '') + (index === 2 ?  'pl20' : '')} key={index}>
            <div className={"card p20 m-m0 "} style={{backgroundColor:tile.background.rgb}}>
                <div className="p20">
                    <p className="h2 m0 mb20">{tile.name}</p>
                    <div className="content sainte">
                         {tile.content}
                    </div>
                </div>
            </div>
        </div>
        ) 
    });
    return(
        <div className="our--approach p20" style={{backgroundColor:background}}>
            <div className="max-1400 ma mt20">
                <div className="pos-rel ratio-1-2">
                    <GatsbyImage className="bg-image" image={image}  alt="Our Approach" /> 
                </div>
                <div className="tiles pt20 grid no-two no-gap">
                    {cards}
                </div>   
            </div>
        </div>
    )
}
export default Image